// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-careers-detail-jsx": () => import("./../src/pages/careers/detail.jsx" /* webpackChunkName: "component---src-pages-careers-detail-jsx" */),
  "component---src-pages-careers-index-jsx": () => import("./../src/pages/careers/index.jsx" /* webpackChunkName: "component---src-pages-careers-index-jsx" */),
  "component---src-pages-careers-submission-jsx": () => import("./../src/pages/careers/submission.jsx" /* webpackChunkName: "component---src-pages-careers-submission-jsx" */),
  "component---src-pages-community-index-jsx": () => import("./../src/pages/community/index.jsx" /* webpackChunkName: "component---src-pages-community-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-document-index-jsx": () => import("./../src/pages/document/index.jsx" /* webpackChunkName: "component---src-pages-document-index-jsx" */),
  "component---src-pages-faq-index-jsx": () => import("./../src/pages/faq/index.jsx" /* webpackChunkName: "component---src-pages-faq-index-jsx" */),
  "component---src-pages-homepage-index-jsx": () => import("./../src/pages/homepage/index.jsx" /* webpackChunkName: "component---src-pages-homepage-index-jsx" */),
  "component---src-pages-login-index-jsx": () => import("./../src/pages/login/index.jsx" /* webpackChunkName: "component---src-pages-login-index-jsx" */),
  "component---src-pages-partners-index-jsx": () => import("./../src/pages/partners/index.jsx" /* webpackChunkName: "component---src-pages-partners-index-jsx" */),
  "component---src-pages-privacy-intro-index-jsx": () => import("./../src/pages/privacy-intro/index.jsx" /* webpackChunkName: "component---src-pages-privacy-intro-index-jsx" */),
  "component---src-pages-privacy-intro-privacy-items-jsx": () => import("./../src/pages/privacy-intro/privacy-items.jsx" /* webpackChunkName: "component---src-pages-privacy-intro-privacy-items-jsx" */),
  "component---src-pages-products-westudents-index-jsx": () => import("./../src/pages/products/westudents/index.jsx" /* webpackChunkName: "component---src-pages-products-westudents-index-jsx" */),
  "component---src-pages-team-index-jsx": () => import("./../src/pages/team/index.jsx" /* webpackChunkName: "component---src-pages-team-index-jsx" */)
}

